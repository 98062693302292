// should be used with ul/ol
@mixin grid-tiles($n, $settings: ()) {
    @include clearfix;

    @supports (display: grid) {
        display: grid;
        grid-column-gap: $spacer;
        grid-template-columns: repeat($n, 1fr);

        > * {
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: auto !important;
        }
    }
}

.container {
    @include clearfix;
    position: relative;
    width: 100%;  // required when used in a flex container
    max-width: $wrapper-width;
    margin: 0 auto;
    padding: 0 $padding;
}

// to be removed after a4 updated
.l-wrapper {
    @extend .container;
}

.l-center-8 {
    @extend .col-lg-8;
    @extend .offset-lg-2;
}

.l-center-6 {
    @extend .col-lg-6;
    @extend .offset-lg-3;
}

.l-frame {
    margin-left: 1rem !important;
    margin-right: 1rem !important;

    @media screen and (min-width: $breakpoint-md) {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }
}

.l-flex-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.l-tiles-2,
.l-tiles-3,
.l-tiles-4 {
    margin: 0;
    padding: 0;
    list-style: none;
}

@media (min-width: $breakpoint) {
    .l-tiles-2 {
        @include grid-tiles(2);
    }

    .l-tiles-3 {
        @include grid-tiles(2);
    }

    .l-tiles-4 {
        @include grid-tiles(2);
    }
}

@media (min-width: $breakpoint-md) {
    .l-tiles-3 {
        @include grid-tiles(3);
    }

    .l-tiles-4 {
        @include grid-tiles(4);
    }
}
