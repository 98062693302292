@use "sass:math";

@mixin multi-line-icon($lines, $line-height: 1.4) {
    $font-size: 0.7 * $lines;
    $size: math.div($lines * $line-height, $font-size);

    line-height: $size;
    min-width: $size * 1em;
    font-size: $font-size * 100%;
}

.action {
    @include clearfix;
    border-bottom: 1px solid $border-color;
    padding: 0.5em 0;

    &:last-child {
        border-bottom: 0;
    }
}

.action__icon {
    @include multi-line-icon(2);
    background-color: $bg-secondary;
    color: $primary;
    text-align: center;
    float: left;
}

.action__main {
    margin-left: (2 * 1.4 + 1) * 1em;
}

@media (min-width: $breakpoint) {
    .action--withdate {
        padding-right: 8em;
        position: relative;
    }

    .action__date {
        position: absolute;
        top: 0.5em;  // padding
        right: 0;
    }
}
