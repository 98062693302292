.dropdown {
    width: fit-content; // ensure dropdowns are not super wide
    position: relative;
    text-align: left;
    margin-bottom: $em-spacer * 0.5;
}

.dropdown-toggle {
    i {
        font-size: 1.25em;
        vertical-align: middle;
    }

    .dropdown-item__icon {
        display: none;
    }
}

.dropdown-menu {
    min-width: 100%; // full width on mobile
    padding: 0;
    border: 1px solid $text-color;
    border-radius: 2px;
    box-shadow: $spacer $spacer 40px $shadow, -$spacer $spacer 40px $shadow;

    a,
    button {
        // FIXME: check dropdowns if dropdown-item used, if so this can be removed
        display: block;
        width: 100%;
        padding: 0.5em;
        color: inherit;
        border-bottom: 1px solid $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $primary;
        }

        &:active {
            color: $text-color-inverted;
        }

        i {
            display: inline-block;
            width: 1.5em;
        }
    }

    &:last-child {
        border-bottom: 0;
    }

    &:last-child button {
        margin-bottom: 0; // rm safari auto 2px box margin
    }

    .dropdown.show & {
        display: block;
    }

    img {
        display: inline-block;
        height: 1.6em;
        width: auto;
        vertical-align: middle;
        margin-right: 0.2 * $spacer;
    }

    @media print {
        display: none;
    }
}
