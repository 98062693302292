.radio__input {
    opacity: 0;
    position: absolute;
}

// use pixels here because otherwise the icon in the :after
// could not have a smaller fontsize
$checkbox-size: 20px;

.radio__text {
    display: block;
    padding-left: $checkbox-size + 7px;

    position: relative;

    &:before,
    &:after {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: $checkbox-size * 0.5;
    }

    &:before {
        background-color: $bg-secondary;
        border: 1px solid $border-color;
    }

    &:after {
        transition: transform 0.3s, opacity 0.3s;
        transform: scale(1.3);
        opacity: 0;

        font-family: "Font Awesome 6 Free", sans-serif;
        font-weight: 900;
        background-color: $primary;
        color: $text-color-inverted;
        font-size: $font-size-sm;
        line-height: 1.35 * $spacer;
        text-align: center;
    }
}

.radio__input:checked + .radio__text:after {
    content: "\f00c"; // check
    transform: scale(1);
    opacity: 1;
}

.radio__text--checkbox {
    &:before,
    &:after {
        border-radius: 0;
    }
}

.radio__input:focus + .radio__text:before {
    @include fake-focus-shadow;
}

.radio__input:disabled + .radio__text {
    &:before,
    &:after {
        background-color: $border-color;
        cursor: not-allowed;
    }
}
