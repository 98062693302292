// stylelint-disable selector-max-specificity

@use "~select2/src/scss/core";

.select2-container--default {
    max-width: 100%;

    .select2-selection--single {
        height: 40px;
        border-color: core.$border-color;
        border-radius: 0.3em;

        display: flex;
        align-items: center;
    }

    .select2-results__option [aria-selected="true"] {
        background-color: $body-bg;
        color: $text-color;
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $secondary;
        color: $text-color-inverted;
    }

    .select2-selection--single .select2-selection__arrow {
        height: 40px;
        width: 40px;

        b {
            border-width: 7px 7px 0;
        }
    }

    &.select2-container--open {
        .select2-selection--single > .select2-selection__arrow > b {
            border-width: 0 7px 7px;
        }
    }

    &.select2-container--focus {
        .select2-selection--single {
            border-color: $secondary;
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            color: $input-border-color;
            cursor: not-allowed;
            background-color: $body-bg;
        }
    }
}
