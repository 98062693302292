.rating-bar {
    display: inline-block;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
    }
}

.rating-button {
    border: 1px solid $border-color;
    font-weight: normal;
    padding: 0 0.4em;

    &.is-read-only,
    &:disabled {
        cursor: default;
    }

    &:first-child {
        margin-right: 0.5 * $spacer;
    }
}

@mixin rating-button($color) {
    color: $color;
    padding: 0.5 * $padding $padding;
    border-radius: 0.3em;

    &:hover,
    &:focus {
        background: $color;
        border-color: $color;
        color: $text-color-inverted;
    }

    &.is-selected {
        background-color: $color;
        border-color: $color;
        color: $text-color-inverted;
    }

    i {
        margin-right: 0.4 * $spacer;
    }

    &.is-read-only,
    &:disabled {
        color: $color;
        background: none;
        border: 0;
    }

    &.is-selected.is-archived,
    &.is-archived {
        border-color: $border-color;
        color: $gray-lighter;
        background: unset;

        &:hover,
        &:focus {
            cursor: default;
        }
    }
}

.rating-up {
    @include rating-button($success);
}

.rating-down {
    @include rating-button($danger);
}

.rating__label {
    display: none;
}