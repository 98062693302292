.hero-unit {
    font-size: $font-size-lg;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    color: $secondary;
}

.hero-unit__wrapper {
    flex: 1 0 40%;
    padding: $padding;
    line-height: 1.2;

    // after dialogue box
    .hero-unit:first-child & {
        margin-top: 16px;
    }

    @media (min-width: $breakpoint) {
        padding-left: 1em;
        font-size: ($font-size-lg + 3.5rem) * 0.5;
    }

    @media (min-width: $breakpoint-md) {
        font-size: 3.5rem;
    }
}

.hero-unit__img {
    flex: 0 1 100%;
    align-self: stretch;

    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;

    // HACK: IE has a bug where min-height is ignored on elements with
    // `display: flex`. So we apply the it here instead of on .hero-unit.
    min-height: $hero-height-mobile;

    @media (min-width: $breakpoint) {
        min-height: ($hero-height-mobile + $hero-height) * 0.5;
    }

    @media (min-width: $breakpoint-md) {
        min-height: $hero-height;
    }
}

.hero-unit__copyright {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media print {
    .hero-unit {
        text-align: center;
        color: $print-black;
    }

    .hero-unit__img {
        display: none;
    }
}
