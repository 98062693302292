.pagination {
    @include clearfix;
    display: inline-block;
    padding: 0;
    padding-right: 1px;
    background-color: $body-bg;
}

.pagination__item {
    float: left;
    display: inline-block;
    border: 1px solid $border-color;

    a,
    button,
    div {
        display: inline-block;
        margin-right: -1px;
        padding: 0.4 * $spacer 0.75 * $spacer;
        text-align: center;
        text-decoration: none;
        line-height: 1.4;

        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            color: $border-color;
        }
    }

    a,
    button {
        &:hover {
            background-color: $bg-secondary;
            color: $primary;
        }
    }

    &.active {
        background-color: $link-color;
        color: $text-color-inverted;
        border-color: $link-color;

        & a,
        & button {
            background-color: $link-color;
            border-color: $link-color;
            color: $text-color-inverted;
        }

        & a:hover,
        & button:hover {
            color: $primary;
        }
    }
}

@media (min-width: $breakpoint-xs) {
    .pagination__item {
        a,
        button,
        div {
            padding: 0.5 * $spacer 0.87 * $spacer;
        }
    }
}
