*,
*:before,
*:after {
    box-sizing: inherit;
}

address {
    font-style: normal;
}

p,
address,
blockquote,
pre,
table,
dl,
ul,
ol,
figure,
fieldset {
    margin-top: 0;
    margin-bottom: $em-spacer;
}

html {
    background-color: $body-bg !important;
    box-sizing: border-box;
    font-family: $font-family-base;
    line-height: 1.4;
}

body {
    margin: 0;
    overflow-x: hidden; // stops horizontal scrolling with timeline
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    margin-bottom: 0.8rem;
    margin-top: 1.5 * $em-spacer;
    font-weight: normal;
}

h1 {
    font-size: $font-size-xxl;
}

h2 {
    font-size: $font-size-xl;
}

h3 {
    font-size: $font-size-lg;
}

h4 {
    font-size: $font-size-md;
}

h5 {
    font-size: $font-size-sm;
}

h6 {
    font-size: $font-size-xs;
}

@media screen and (max-width: $breakpoint-xs-down) {
    h1 {
        font-size: $mobile-headline-size * $font-size-xxl;
    }

    h2,
    h3 {
        font-size: $mobile-headline-size * $font-size-xl;
    }
}

a {
    color: $link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $link-hover-color;
    }
}

a:not([href]) {
    color: inherit;
    text-decoration: inherit;

    &:hover,
    &:focus {
        color: inherit;
    }
}

img {
    max-width: 100%;
    height: auto;

    .rich-text,
    .block & {
        // to test on dev
        height: auto !important;
    }
}

textarea,
select[multiple] {
    resize: vertical;
}

ol,
ul {
    padding-left: 1.5rem;
}

dd {
    margin: 0;
}

dt {
    font-weight: bold;
    margin-bottom: $em-spacer * 0.5;
    margin-top: $em-spacer;
}

// FIXME ensure auto added buttons look inline with rest of UI eg:close on alerts
button {
    appearance: none;
    color: inherit;
    background: none;
    font-size: inherit;
    border: 0;
    cursor: pointer;
}

%control-base {
    border: 1px solid $input-border-color;
    border-radius: 0.3em;
    padding: 0.4em 0.6em;
    line-height: 1.6;
    font-family: inherit;
    font-size: inherit;
}
