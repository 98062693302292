.u-primary {
    color: $primary;
}

.u-secondary {
    color: $secondary;
}

.u-spacer-left {
    margin-left: $spacer;
}

.u-spacer-right,
.a4-spacer--right {
    margin-right: $spacer;
}

.u-spacer-right-half {
    margin-right: ($spacer * 0.5);
}

.u-spacer-bottom {
    margin-bottom: $spacer;
}

.u-spacer-top {
    margin-top: $spacer;
}

.u-spacer-top-one-half {
    margin-top: ($spacer * 1.5);
}

.u-spacer-top-half {
    margin-top: ($spacer * 0.5);
}

.u-spacer-top-double {
    margin-top: ($spacer * 2);
}

.u-spacer-bottom-half {
    margin-bottom: ($spacer * 0.5);
}

.u-spacer-bottom-double {
    margin-bottom: ($spacer * 2);
}

.u-spacer-bottom-triple {
    margin-bottom: ($spacer * 3);
}

.u--bg-secondary {
    background-color: $bg-secondary;
}

.u-list-reset {
    list-style: none;
    margin: 0;
    padding: 0;

    > * {
        display: block;
    }
}

.u-if-collapsed {
    display: none;

    [aria-expanded="false"] > & {
        display: inline;
    }
}

.u-if-not-collapsed {
    display: none;

    [aria-expanded="true"] > & {
        display: inline;
    }
}

.u-inline {
    display: inline;
}

.u-inline-flex {
    display: inline-flex;
}

.u-flex {
    display: flex;
}

.u-space-between {
    display: flex;
    justify-content: space-between;
}

.u-align-right {
    text-align: right;
}

.u-align-center {
    text-align: center;
}

.u-first-heading {
    margin-top: 0;
}

.u-danger {
    color: $danger;
}

.u-success {
    color: $success;
}

.u-muted,
.a4-muted {
    color: $text-color-gray;
}

.u-text-decoration-line-through {
    text-decoration: line-through;
}

.u-whitespace-pre {
    white-space: pre-wrap;
}

.u-position-relative {
    position: relative;
}

.u-z-index-2 {
    z-index: 2;
}

.u-z-index-1 {
    z-index: 1;
}

.u-no-border,
.u-no-border:first-child,
.u-no-border:last-child {
    border: none;
}

.u-no-margin {
    margin: 0;
}

.u-no-margin-bottom {
    margin-bottom: 0;
}

.u-no-padding {
    padding: 0;
}

.d-none {
    display: none;
}

.u-top-divider {
    padding-top: $padding;
    border-top: 1px solid $border-color;
}

.u-bottom-divider {
    padding-bottom: $padding * 2;
    margin-bottom: $spacer * 2;
    border-bottom: 1px solid $border-color;
}

.u-border {
    border: 1px solid $border-color;
}

.u-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.u-break-word {
    overflow-wrap: break-word;
}

.u-overflow-visible {
    overflow: visible;
}

.u-xs-down-display-none {
    @media screen and (max-width: $breakpoint-xs-down) {
        display: none !important;
    }
}

.u-md-down-display-none {
    @media screen and (max-width: $breakpoint-md-down) {
        display: none !important;
    }
}

.u-md-up-display-none {
    @media screen and (min-width: $breakpoint-md) {
        display: none !important;
    }
}

.u-xs-md-display-none {
    @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-md-down) {
        display: none !important;
    }
}

.u-xs-only-display {
    @media screen and (min-width: $breakpoint-xs) {
        display: none !important;
    }
}

.u-lg-only-display {
    @media screen and (max-width: $breakpoint-lg-down) {
        display: none !important;
    }
}

.u-font-italic {
    font-style: italic;
}

.u-icon-spacing {
    margin-right: ($spacer * 0.4);
    margin-top: ($spacer * 0.25);
}

.u-spinner__container {
    width: 100%;
    justify-content: center;
    display: flex;
}

.u-bold {
    font-weight: bold;
}

.a4-sr-only {
    @extend .visually-hidden;
}
