.paragraph {
    margin-bottom: $em-spacer;
    border: solid 1px $border-color;
    border-radius: 5px;
}

.chapter-title,
.paragraph__content,
.paragraph__actions {
    padding: $padding;
}

.paragraph__content {
    margin-bottom: 1px;
}

.chapter-title,
.paragraph__title {
    margin-top: 0;
}

.paragraph__actions {
    text-align: right;
    border-top: solid 1px $border-color;
}
