.label {
    display: inline-block;
    background-color: $bg-tertiary;
    color: $text-color;
    font-size: $font-size-xs;
    font-weight: normal;
    border-radius: 0.4em;
    padding: 0.2em 0.7em;

    &__link {
        display: inline-block;
        background-color: transparent;
        overflow: hidden;
    }
}

.label--big {
    padding: 0.4em 0.6em;
    font-size: $font-size-sm;
    border-radius: 0.3em;
}

.label--primary {
    background-color: $primary;
    color: $text-color-inverted;
}

.label--secondary {
    background-color: $secondary;
    color: $text-color-inverted;
}

.label--active {
    color: $primary;
    background-color: $text-color-inverted;
    font-weight: bold;
}

.label--consideration,
.label--qualified {
    background-color: $feedback-color-consideration;
    color: $text-color;
}

.label--rejected {
    background-color: $feedback-color-rejected;
    color: $text-color-inverted;
}

.label--accepted {
    background-color: $feedback-color-accepted;
    color: $text-color-inverted;
}

@media print {
    .label {
        color: $print-black;
        background-color: $text-color-inverted;
        border: 1px solid $border-color;
    }
}
