.widget--datetimeinput {
    @include clearfix;
}

.widget--datetimeinput__date {
    @extend .input-group;
}

.flatpickr-calendar {
    // overwrite style from _form.scss
    select {
        display: unset;
    }

}
