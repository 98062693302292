// Overwrites of the default styling

.video-wall {
    font-family: $font-family-sans-serif;
    min-width: 100% !important;
    border: 0.5em solid $border-color;
}

.video-wall button {
    background-color: $info;
    border-radius: 0.3em;
}
