input,
textarea,
select {
    @extend %control-base;
    display: block;
    max-width: 100%;
    background-color: $body-bg;
    color: $text-color;

    &:invalid {
        border-color: $danger;
        color: $danger;
    }

    &:disabled {
        color: $input-border-color;
        cursor: not-allowed;
    }

    &:focus {
        border-color: $secondary;
    }

    &::placeholder {
        opacity: 0.5;
    }
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
    border: 0;
}

input[type="search"] {
    border-top-left-radius: 0.3em !important;
    border-bottom-left-radius: 0.3em !important;
}

div.cke_focus {
    border-color: $secondary;
}

.errorlist,
.field-error {
    margin: 0 0 $spacer;
    padding: $padding;
    list-style: none;
    text-align: center;
    background-color: $danger;
    color: $text-color-inverted;
}

.django-ckeditor-widget {
    display: block !important;
    margin-bottom: $spacer;
}


.form-check,
.form-group {
    margin: 0 0 $spacer;


    .django-ckeditor-widget,
    input,
    textarea,
    select {
        width: 100%;

        // FIXME: Generally, we would prefer to use .form-group everywhere and
        // not have a margin on inputs at all. We do not control all HTML
        // though (e.g. django standard forms or react components from
        // adhocracy4 core).
        margin-bottom: 0;
    }

    // hide ckeditor input field
    .django-ckeditor-widget textarea {
        display: none;
    }
}

.form-fieldset {
    padding: 0;
    border: none;
}

.form-check__label {
    input {
        display: inline;
        width: auto;
        margin-left: -1.25 * $spacer;
    }

    span {
        margin-left: 0.5 * $spacer;
    }
}

.form-hint {
    font-style: italic;
    color: $text-color-gray;
    font-size: $font-size-sm;
    margin-bottom: 0.5 * $spacer;
}

.form-hint--margin {
    margin-bottom: $spacer;
}

.form__subheading {
    margin-bottom: 0;
}

.widget--textinput {
    flex-grow: 1;
}

.widget--radioselect,
.widget--checkboxselectmultiple,
.widget--radioselectwithtextinputwidget {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    label {
        display: inline-block;
        padding-bottom: 0.5 * $padding;
        padding-left: 1.25 * $padding;

        @extend .form-check__label;
    }

    input[type="checkbox"] {
        transform: scale(1.5);
        margin-right: 0.5 * $spacer;
    }
}

.widget--votingtokenwidget {
    input {
        margin-bottom: $spacer;
    }

    @media screen and (min-width: $breakpoint-xs) {
        display: flex;

        input {
            margin-right: $spacer;
            margin-bottom: 0;
        }
    }
}
