.tile {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    margin-bottom: $padding;
    page-break-inside: avoid;
}

.tile--container {
    background-color: $bg-secondary;
    border-color: $text-color;
}

.tile__head {
    position: relative;

    @media print {
        display: none;
    }
}

.tile__image {
    display: block;
    min-height: 11.8em;

    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: $secondary;
}

.tile__image--contained {
    background-size: contain;
}

.tile__image--logo {
    background-color: $body-bg;
    border-bottom: 1px solid $border-color;
}

.tile__image--shadowed {
    &:before {
        content: " ";
        width: 100%;
        height: 6em;

        position: absolute;
        bottom: 0;

        // add an additional step for a smoother gradient
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
    }
}

.tile__image__copyright {
    position: absolute;
    top: 0;
    right: 0;
}

.tile__body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: $padding;

    > :last-child {
        margin-bottom: 0;
    }
}

.tile__text {
    flex: 1 0 auto;
}

.tile__title {
    font-size: $font-size-lg;
    margin-top: 0;
    margin-bottom: 0.5 * $em-spacer;
}

.tile__badge {
    height: 3.4em;
    width: 3.4em;
    text-align: center;
    border-radius: 50%;

    position: absolute;
    bottom: 0.8em;
    right: 0.8em;

    i {
        font-size: 1.7em;
        line-height: 2;
    }
}

.tile__badge--external {
    background-color: $bg-secondary;
    color: $primary;
}

.tile__badge--container {
    background-color: $secondary;
    color: $bg-secondary;
}

.tile__labels {
    position: absolute;
    bottom: 0.8em;
    left: 0.8em;
}

.tile__hint {
    color: $danger;
    font-size: $font-size-sm;

    @media print {
        color: $print-black;
    }
}

.tile__hint--container {
    color: $text-color;
}

.tile__hint__big {
    font-size: 1rem;
    font-weight: bold;
}
