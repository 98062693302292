.block {
    @include clearfix;
    margin-bottom: 2.8em;
    page-break-inside: avoid;
}

.block--cta {
    padding: 2.8em 0;

    background-color: $secondary;
    color: $text-color-inverted;
    text-align: center;

    .col-lg-6 > :first-child {
        margin-top: 0;
    }

    @media print {
        background-color: transparent;
        color: $print-black;
    }
}

.block--bordered {
    padding: 1em;
    border: 1px solid $border-color;

    @media (max-width: $breakpoint-down) {
        margin: 0;
        width: 100%;
    }

    h2 {
        margin-top: 0;
    }
}

.block__actions {
    text-align: center;
}

.block--image-cta {
    border: 1px solid $border-color;
}

.block--image-cta__img {
    display: block;
    position: relative;
    min-height: 11.8em;
    background-size: stretch;
    background-position: center;
    margin-bottom: $em-spacer;
}

.block--image-cta__body {
    padding: $padding;
    text-align: center;
}

.block--image-cta__copyright {
    position: absolute;
    bottom: 0;
    right: 0;
}

.block__homepage .block--richtext {
    p,
    h2 {
        text-align: center;
    }

    h2 {
        color: $secondary;
        font-size: $font-size-xxl;
        margin-top: $spacer;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.block--infographic {
    background-color: $secondary;
    color: $body-bg;

    padding-top: $padding;

    @media screen and (min-width: $breakpoint-xs) {
        padding-top: 2 * $padding;
    }

    @media print {
        background-color: transparent;
        color: $print-black;
    }
}

.block--infographic__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $breakpoint-xs) {
        flex-direction: row;
        align-items: flex-start;
    }

    @media screen and (min-width: $breakpoint-md) {
        align-items: center;
    }
}

.block--infographic__icon-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: $breakpoint-xs) {
        flex-direction: column;
        min-width: 25%;
    }

    @media screen and (min-width: $breakpoint-md) {
        flex-direction: row;
        min-width: 0;
    }
}

.block--infographic__icon {
    padding-right: 2 * $padding;
    padding-top: 0.5 * $padding;
    padding-bottom: 0.5 * $padding;
    width: 6rem;
    flex-shrink: 0;

    img {
        width: 70%;
    }

    @media screen and (min-width: $breakpoint-xs) {
        width: 4rem;
        padding-right: 0;
        flex-shrink: 1;
    }

    @media screen and (min-width: $breakpoint-md) {
        width: 8rem;
        padding-right: 2 * $padding;
    }
}

.block--infographic__text {
    @media screen and (min-width: $breakpoint-xs) {
        text-align: center;
    }

    @media screen and (min-width: $breakpoint-md) {
        text-align: left;
    }
}

.block--infographic__arrow {
    display: none;

    img {
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-xs) {
        padding-top: 2 * $padding;
        display: inline;
        width: 7rem;
    }

    @media screen and (min-width: $breakpoint-md) {
        padding-right: 3 * $padding;
        padding-left: 3 * $padding;
        padding-top: 0;
        width: 13rem;
    }
}

.block--infographic__arrow--down {
    margin: auto;
    width: 15%;
    transform: rotate(90deg);

    @media screen and (min-width: $breakpoint-xs) {
        display: none;
    }
}

.block--map-teaser {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    max-width: $wrapper-width;
    margin: auto;
    margin-top: (-15 * $spacer);
    margin-bottom: (-$spacer);
    padding-top: 25 * $padding;
    padding-bottom: 16 * $padding;
}

.block--map-teaser_background {
    background-image: radial-gradient($white-shadow, rgba(0, 0, 0, 0));
    border-radius: 100px;
    text-align: center;
}

// as filter is different on homepage styling must be nested
.block--map-teaser__filter {
    .filter-bar-container {
        margin: 2 * $spacer 0 0 0;
    }

    .filter-bar {
        margin-top: 3 * $spacer;
        box-shadow: 0 0 20px 0 $shadow;
        background-color: $body-bg;
        color: $text-color;
    }

    .filter-bar__btn {
        color: $gray-lighter;

        &:focus,
        &:hover,
        &:active,
        &:disabled {
            color: $gray-lighter;
            border-color: $gray-lighter;
        }
    }

    .filter-bar__btn--unselected {
        border-bottom: 1px dotted $gray-lighter;
    }

    .filter-bar--open {
        background-color: $bg-secondary;
        color: $text-color;
    }

    .filter-bar__dropdown-menu {
        left: 1rem;
        right: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-top: 1.5 * $spacer;
        padding-top: 2 * $padding;
        border-top: 2px solid $white;
        z-index: 3;
    }
}

@media screen and (max-width: $breakpoint-md-down) {
    .block--map-teaser {
        padding-top: 15 * $padding;
        padding-bottom: 11 * $padding;

        .filter-bar__dropdown {
            width: 30%;
        }
    }
}
