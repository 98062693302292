.project-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.3em inherit;
    position: relative;
    text-align: center;

    @media print {
        padding-top: 0;
    }
}

.project-header--image {
    min-height: $hero-height-mobile;
    color: $text-color-inverted;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;

    @media (min-width: $breakpoint) {
        min-height: ($hero-height-mobile + $hero-height-secondary) * 0.5;
    }

    @media (min-width: $breakpoint-md) {
        min-height: $hero-height-secondary;
    }

    &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(#000, 0.5);
    }

    @media print {
        min-height: 0;
        color: $print-black;
        background-image: none !important;

        &:before {
            content: none;
        }
    }
}

.project-header__title {
    margin-top: 0.3rem;
    margin-bottom: 1.2rem;
    font-size: $font-size-xl;
    overflow-wrap: break-word;

    @media (min-width: $breakpoint) {
        font-size: $font-size-xxl;
    }
}

.project-header__copyright {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}

.project-header__description {
    overflow-wrap: break-word;
}

.project-header__logo {
    border: 1px solid $border-color;
}

.project-header__follow {
    margin-bottom: 2 * $spacer;
}

@media (min-width: $breakpoint) {
    .project-header__logo-wrapper {
        display: flex;
        align-items: flex-start;
        text-align: left;
    }

    .project-header__logo {
        flex: 0 0 auto;
        margin-right: $padding;
    }

    .project-header__logo-body {
        flex: 1 1 auto;
        align-self: center;
    }
}
