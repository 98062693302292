@use "sass:color";

$messages-margin-bottom: 25px;

.messages {
    margin: 0 0 $messages-margin-bottom;
    padding: 0;

    li {
        display: block;
    }

    + .messages {
        margin-top: -$messages-margin-bottom;
    }
}

.alert {
    margin: 0;
    padding: $padding * 1.2 0;
    background-color: color.adjust($info, $lightness: 60%);
    color: $info;
    text-align: center;
}

.alert--success {
    background-color: color.adjust($success, $lightness: 70%);
    color: $success;
}

.alert--error,
.alert--danger {
    background-color: color.adjust($danger, $lightness: 50%);
    color: $danger;
}

.alert--warning {
    background-color: color.adjust($warning, $lightness: 48%);
    color: $warning;
}

.alert__close {
    float: right;
    color: inherit;
}

.alert--small {
    padding: 0.5em;
}

// If this directly follows the dialog box (see header.scss), add 16px
// additional padding to the default value (see above).
.messages:first-child > :first-child {
    padding-top: calc(16px + #{$padding * 1.2});
}

// compensate for margin-bottom on messages
.project-header,
.hero-unit {
    .messages + & {
        margin-top: -$messages-margin-bottom;
    }
}
