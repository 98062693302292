// standard size from radio.scss
$checkbox-size: 20px;

.pollquestionlist-container {
    border: 1px solid $border-color;

    .alert--success {
        margin: 0 1.5 * $spacer;
    }
}

.poll {
    padding: 1.5 * $spacer;
    background-color: $body-bg;

    h2 {
        margin-top: 0;
    }
}

// PollQuestion/s
.poll-row {
    display: flex;
    position: relative;
    z-index: 1;
    padding: 0.2 * $spacer 0;
    margin-bottom: 0.5 * $spacer;

    @include clearfix;

    &--answered {
        left: 2 * $spacer;
    }

    // FIXME
    .form-control {
        margin-left: $checkbox-size + 7px !important;
        margin-top: 0.5 * $spacer;
        width: auto;
    }
}

.poll-row.radio {
    flex-direction: column;
}

.poll__rows {
    display: flex;
    flex-direction: column;
    padding-bottom: $spacer;
    border-bottom: 1px solid $border-color;
}

.poll__char-counter {
    align-self: flex-end;
    font-size: $font-size-xs;
    color: $text-color-gray;
}

.poll__actions > :last-child {
    margin-top: 0.5 * $spacer;
    display: block;

    @media (min-width: $breakpoint) {
        display: inline-block;
    }
}

.poll-row__number,
.poll-row__label,
.poll-row__radio {
    display: inline-block;
    margin: 0;
    margin-right: 0.2 * $spacer;
}

.poll-row__radio {
    @extend .radio__input;
}

.poll__btn--link {
    @extend .btn--link;
}

.poll-row__container {
    position: relative;

    .poll__btn--link {
        margin: 0.1 * $spacer 0 0.6 * $spacer 0; // corrects spaceing for other answers
    }
}

// RESULTS
.poll-row__bar {
    background-color: $bg-secondary;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.3em;
    z-index: -1;
    width: 0;
    min-width: 0.4em;

    transition: width 0.3s;

    &.poll__highlight {
        max-width: 90%;

        @media (min-width: $breakpoint) {
            max-width: 96%;
        }
    }

    @media print {
        border: 1px solid $border-color;
    }
}

.poll-row > .poll__highlight {
    background-color: $primary-tint;
}

.poll-row__chosen {
    font-size: $font-size-lg;
    color: $gray-lighter;
    position: absolute;
    top: 0.25 * $spacer;
}

.poll-row__number {
    padding-left: 0.5 * $spacer;
    min-width: 5em;
    font-weight: bold;
}

.poll__btn--light,
.editpoll__btn--question,
.editpoll__btn--delete {
    @extend .btn--light;
}

.editpoll__btn--question {
    margin: 0.5 * $spacer 0;
}

.poll__btn--dark,
.editpoll__btn--dark {
    @extend .btn--primary;
}

.poll__btn--wrapper {
    display: flex;
    align-items: center;
}

// EditPollQuestions/EditPollOpenQuestions

.editpoll__help-text,
.poll__help-text {
    color: $gray-lighter;
    margin-top: -0.7 * $spacer;
    margin-bottom: 0.8 * $spacer;
}

.editpoll__help-text {
    color: $gray-lighter;
    margin-top: -0.7em;
    margin-bottom: 0.5em;
}

.editpoll__dropdown .dropdown-menu {
    border-radius: 0.3em;
    box-shadow: none;
}

.editpoll__btns--question {
    display: flex;
    flex-flow: column wrap;
}

@media (min-width: $breakpoint) {
    .editpoll__btns--question {
        flex-direction: row;
    }

    .editpoll__btn--question {
        margin: 0;
        margin-right: 0.5 * $spacer;
    }
}

// Open/Other answers

.poll-slider__item {
    padding: $spacer;
}

.poll-slider__answer {
    padding-bottom: 2 * $spacer;

    i {
        color: $gray-lighter;
    }
}

.poll-slider__count,
.poll-slider__count--spaced {
    font-size: $font-size-xs;
    color: $gray-lighter;
    position: absolute;
    bottom: 1rem;
    right: 6rem;
}

.poll-slider {
    position: relative;
    margin-bottom: $spacer;

    // slick overwrites - nested for specificity
    .slick-prev {
        left: revert !important;
        right: 3.5 * $spacer !important;
    }

    .slick-next {
        right: 1.5 * $spacer !important;
    }

    .slick-prev,
    .slick-next {
        @extend .btn;
        @extend .btn--primary;
        position: absolute;
        top: revert;
        bottom: 0;
        text-align: center;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        z-index: 1; // for when tile links overlap

        &.slick-disabled {
            opacity: 0.25;
            cursor: not-allowed;
            pointer-events: none;
            box-shadow: none;
        }

        &:before {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $body-bg;
            opacity: 1;
            font-family: "Font Awesome 6 Free", sans-serif;
            font-size: 18px;
        }
    }

    .slick-prev:before {
        content: "\f104"; // angle-left
    }

    .slick-next:before {
        content: "\f105"; // angle-right
    }

    // Ensure centermode works
    .slick-slide {
        height: auto;
        position: relative;
        border-radius: 0.3 * $spacer;
        background-color: $bg-secondary;
    }

    .slick-track {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
    }
}

@media (min-width: $breakpoint) {
    .poll-slider__count--spaced {
        left: revert;
        right: 8.5 * $spacer;
    }
}
