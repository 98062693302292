.dialogue-box__container {
    position: relative;
}

.dialogue-box__background-left,
.dialogue-box__background-right {
    position: absolute;
    height: 94px;
    background-image: url("/static/images/dialoguebox_border.png");
    background-repeat: repeat-x;
    bottom: 0;
    z-index: 1;
    width: 15%;

    @media screen and (min-width: $breakpoint-lg) {
        width: 20%;
    }

    // if user zooms out
    @media screen and (min-width: $breakpoint-lg + 50rem) {
        width: 40%;
    }
}

.dialogue-box__background-left {
    left: 0;
}

.dialogue-box__background-right {
    right: 0;
}

.dialogue-box {
    z-index: 2;
    display: flex;

    > div {
        height: 95px;
    }
}

.dialogue-box__left,
.dialogue-box__right {
    background-image: url("/static/images/dialoguebox_border.png");
    background-repeat: repeat-x;
    margin: 1px -1px -1px 0; // fix for gop in chrome, safari zoom
}

.dialogue-box__left {
    flex: 1 0 auto;

    @media screen and (min-width: $breakpoint-xs) {
        flex: 1 0 auto;
    }

    @media screen and (min-width: $breakpoint) {
        flex: 3 0 auto;
    }

    @media screen and (min-width: $breakpoint-md) {
        flex: 4.05 2 auto;
    }
}

.dialogue-box__right {
    margin: 1px -1px -1px 0;
    flex: 0 1 15.8rem;
    background-image: url("/static/images/dialoguebox.png");
    background-repeat: no-repeat;
}
