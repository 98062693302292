.dashboard-nav {
    border: 1px solid $border-color;
    border-radius: 0.3em 0.3em 0 0;
    overflow: hidden;
}

.dashboard-nav:first-child {
    border-radius: 0.3em;
}

.dashboard-nav__dropdown {
    background-color: $bg-secondary;

    .fa-caret-down {
        font-size: 1.7rem;
        transition: transform 0.3s;
    }
}

input:checked ~ .dashboard-nav__dropdown {
    .fa-caret-down {
        transform: rotate(180deg);
    }
}

.dashboard-nav__pages {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dashboard-nav__page {
    border-top: 1px solid $border-color;
}

.dashboard-nav__item {
    display: block;
    padding: 0.7em;
    border-left: 0.3em solid transparent;
    color: $text-color;
    text-decoration: none;
}

.dashboard-nav__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.dashboard-nav__breadcrumb {
    padding-top: 1.5 * $padding;
    padding-bottom: 1.5 * $padding;
}

.dashboard-nav__icon {
    margin-right: 1.9 * $em-spacer;
}

.dashboard-nav__dropdown-icon {
    margin-right: 0.5 * $em-spacer;
    vertical-align: super;
}

.dashboard-nav__item--interactive {
    &:hover,
    &:focus {
        color: $primary;
    }

    &.is-active {
        color: $primary;
        border-left-color: $primary;
    }

    i {
        float: right;
        line-height: inherit;
    }
}

.dashboard-nav__collapsible-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dashboard-nav__collapsible-menu {
    a {
        display: block;
    }

    label {
        display: block;
        cursor: pointer;
        margin: 0;
    }

    input {
        position: absolute;
        opacity: 0;
    }
}

.dashboard-nav__menu-content {
    max-height: 0;
    overflow: hidden;
}

/* Toggle Effect */
input:checked ~ .dashboard-nav__menu-content {
    max-height: 100%;
}

.dashboard-nav__module-control {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 $em-spacer;
}

.dashboard_nav__form--project {
    flex-grow: 1;
}
