$timeline-carousel__top-space: 11px;

.timeline-carousel__item { // to stop items displaying as a list before js is initialized
    visibility: hidden;

    &.slick-initialized {
        visibility: visible;
    }
}

.timeline-carousel__dot {
    display: inline-block;
    background-color: $border-color;
    padding: 0;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    vertical-align: middle;
    transition: transform 0.2s;
    transform-origin: 50% 50%;
}

.timeline-carousel__label {
    padding: $padding;
    margin-top: 2 * $em-spacer;
    color: $secondary;
    background-color: $blue-light;
    text-align: left;
    transition: transform 0.2s;
    transform-origin: 50% 50%;
}

.timeline-carousel__line {
    position: absolute;
    width: 200%;
    left: -50%;
    top: $timeline-carousel__top-space;
    border-top: 2px dashed $border-color;
}

.timeline-carousel__wrapper {
    overflow: hidden;
}

.slick-slide:focus,
.slick-slide:hover {
    .timeline-carousel__dot {
        transform: scale(1.85);
    }

    .timeline-carousel__label {
        transform: scale(1.05);
    }
}

.timeline-carousel {
    position: relative;
    color: $secondary;
    text-align: center;
    margin: $em-spacer 0;

    // slick overwrites - nested for specificity
    .slick-slide {
        margin: 0 3 * $em-spacer;
        min-width: 275px;
    }

    .slick-prev,
    .slick-next {
        top: $timeline-carousel__top-space;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px solid $border-color;
        background-color: $body-bg;
        z-index: 1; // for when tile links overlap

        &:hover,
        &:focus,
        &:active {
            border: 2px solid $secondary;
        }

        &:before {
            color: $secondary;
            opacity: 1;
            font-family: "Font Awesome 6 Free", sans-serif;
            font-weight: 900;
            font-size: $font-size-xxl;
            line-height: 1.3rem;
        }
    }

    .slick-prev:before {
        content: "\f104"; //  angle-left
    }

    .slick-next:before {
        content: "\f105"; //  angle-right
    }
}

// mobile slick overwrites
@media screen and (max-width: $breakpoint-lg-down) {
    .slick-next {
        right: 0 !important;
    }

    .slick-prev {
        left: 0 !important;
    }
}

.initial {
    background: $secondary;
    color: $text-color-inverted;

    &.timeline-carousel__label {
        padding: 1.5 * $padding;

        .timeline-carousel__title {
            font-weight: bold;
        }
    }

    &.timeline-carousel__dot {
        transform: scale(1.85);
    }
}

@media screen and (min-width: $breakpoint) {
    .timeline-carousel__wrapper {
        overflow: visible;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .timeline-carousel__wrapper {
        margin-bottom: 3 * $spacer;
    }
}
