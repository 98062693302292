// mimic native browser focus
// see https://ghinda.net/article/mimic-native-focus-css/

@mixin fake-focus {
    // stylelint-disable scale-unlimited/declaration-strict-value
    outline: 2px solid Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    // stylelint-enable
}

// mimic native browser focus with box-shadow, which respects border-radius
@mixin fake-focus-shadow {
    // stylelint-disable scale-unlimited/declaration-strict-value
    box-shadow: 0 0 1px 3px Highlight;
    // stylelint-enable
}

@mixin fake-focus-shadow-light {
    // stylelint-disable scale-unlimited/declaration-strict-value
    box-shadow: 0 0 1px 3px rgb(211, 212, 213, 0.5);
    // stylelint-enable
}

@mixin secondary-text {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
