.idea-remark {
    min-width: 600%;
    background-color: $idea-remark-bg;
    padding-top: $em-spacer;
    padding-left: $em-spacer;
    padding-right: $em-spacer;
    box-shadow: 0 0 10px 0 $shadow;
    border: none;
}

.idea-remark__hint {
    padding-top: $em-spacer;
    font-style: italic;
    color: $idea-remark-color;
    font-size: $font-size-sm;
}

.idea-remark__hint .fa,
.idea-remark__hint .fas,
.idea-remark__hint .far {
    padding-right: $em-spacer;
}

.idea-remark__text textarea {
    min-height: 8em;
    background-color: $idea-remark-bg;
    font-style: italic;
    color: $black;
}

.idea-remark__btn__notify {
    color: $success;
    position: absolute;
    background-color: $white;
    top: -8px;
    right: -5px;
    z-index: 1;
}

.idea-remark__btn__comment {
    position: relative;
    z-index: 2;
}
