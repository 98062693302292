.typeahead__input-group {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-top: $spacer;

    // align input and autocomplete letters
    .rbt-input-hint-container {
        div {
            top: -1px !important;
        }
    }
}

.typeahead__input-group-prepend {
    display: flex;
    margin-right: -1px;
}

.typeahead__input-group-append {
    display: flex;
    flex: 2;
}

.typeahead__input-group-text {
    display: flex;
    align-items: center;
    padding: $input-padding-y $spacer;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 0;
}
