$circle-size: 12px;

.timeline {
    font-size: $font-size-sm;
    padding-left: 2em;
    margin-bottom: $em-spacer;
    color: $link-color;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: block;
        position: relative;
        margin-bottom: 5px;

        &:last-child {
            margin-right: 0;
        }

        &:before {
            content: "";
            width: 1px;

            position: absolute;
            top: -15px;
            bottom: -15px;
            left: -$circle-size;

            background-color: $border-color;
        }

        &:first-child:before {
            top: 50%;
        }

        &:last-child:before {
            bottom: 50%;
        }
    }
}

.timeline__button {
    &:after {
        content: "";
        width: $circle-size;
        height: $circle-size;
        z-index: 1;

        position: absolute;
        top: 50%;
        left: (-$circle-size * 1.5);
        margin-top: (-$circle-size * 0.5);

        background-color: $body-bg;
        border: 1px solid $border-color;
        border-radius: 50%;
        box-shadow: 0 0 0 0.2em $body-bg;
    }
}

@media (min-width: $breakpoint) {
    .timeline {
        text-align: center;
        padding-left: 0;
    }

    .timeline li {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 40px + $circle-size;

        &:before,
        &:first-child:before,
        &:last-child:before {
            width: auto;
            height: 1px;

            // stylelint-disable-next-line scss/operator-no-unspaced
            inset: auto -15px (-$circle-size);
        }

        &:first-child:before {
            left: 50%;
        }

        &:last-child:before {
            right: 50%;
        }
    }

    .timeline__button:after {
        z-index: auto;

        top: auto;
        margin-top: 0;

        bottom: (-$circle-size * 1.5);
        left: 50%;
        margin-left: (-$circle-size * 0.5);
    }

    .timeline__active {
        position: absolute;
        bottom: (-$circle-size * 1.5 - 24px);
        left: 0;
        right: 0;
        text-align: center;
    }
}

.timeline-detail {
    margin-bottom: $em-spacer;
}

.timeline-detail__title {
    font-size: $font-size-xl;
}

.timeline-detail__meta {
    padding-bottom: 0.6em;
}

.phase-info__item {
    padding: $padding 0;

    &:last-child {
        margin-bottom: $em-spacer;
    }
}

.phase-info__item__title {
    font-weight: bold;
}

.phase-info__highlight {
    font-weight: normal;
    color: $primary;
}
