.item-detail-2__smalltitle {
    font-weight: bold;
    font-size: inherit;
    margin: 1em 0 0;
}

.item-detail-2__factsheet {
    padding-top: 1em;
    border-bottom: 1px solid $border-color;
}

.item-detail-2__factsheet-table {
    padding-left: 0;
    display: table;

    div {
        display: table-row;
    }

    dt {
        display: table-cell;
        border-right: 2em solid transparent;
    }

    dd {
        display: table-cell;
    }
}

.item-detail-2__content {
    margin-top: 2em;

    border-bottom: 1px solid $border-color;
}

.item-detail-2__content--borderless {
    margin-top: 2em;
}

.item-detail-2__project-title {
    margin-left: $spacer;
    font-weight: bold;
    font-size: inherit;
}

.item-detail-2__project-list {
    padding-top: $padding;
    padding-bottom: $padding;
    background-color: $bg-secondary;
}

.item-detail-2__info-box {
    border: 1px solid $border-color;
    padding: 1em;
    margin: 2 * $spacer 0;

    .item-detail-2__smalltitle {
        margin-top: 0;
    }

    button {
        color: inherit;
        line-height: 2;
    }
}
