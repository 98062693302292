// used for icon selection for map category

.select-dropdown {
    width: 100%;
}

.select-dropdown__btn {
    @extend .btn--light;
    width: 100%;

    i {
        position: absolute;
        top: 0.8em;
        right: 0.6em;
    }

    text-align: left;
    font-weight: normal;
}

.select-dropdown__btn__label__icon,
.select-dropdown__item__icon {
    height: 1.6em;
    vertical-align: middle;
    margin-right: 0.6em;
}
