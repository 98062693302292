$header-margin-bottom: 25px;

.main-header {
    position: relative;
    z-index: 1;
}

.main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5;
    margin-bottom: $header-margin-bottom;

    @media screen and (min-width: $breakpoint-md) {
        align-items: flex-end;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .main-nav__nav-item {
        flex: 1;
    }

    .main-nav__nav-item--right {
        display: flex;
        justify-content: flex-end;
    }
}

.main-nav__logo-item {
    position: relative;
}

.main-nav__logo {
    text-decoration: none;

    img {
        margin: 0;
        width: 5rem;
        vertical-align: middle;

        @media screen and (min-width: $breakpoint-md) {
            width: 8rem;
        }
    }
}

.main-nav__menu-wrapper {
    padding: 0.5em $padding 0;
    border-bottom: 1px solid $border-color;

    position: absolute;
    left: 0;
    right: 0;
    top: 5rem;
    z-index: 1;

    background-color: $body-bg;

    @media screen and (min-width: $breakpoint-md) {
        padding: 0;
        border: 0;
        position: static;
        background-color: transparent;

        &.collapse {
            display: inline-block;
        }
    }
}

.main-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;

    @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
        padding-bottom: 2em;
    }
}

.main-nav__item {
    display: block;
    border-top: 1px solid $border-color;

    @media screen and (min-width: $breakpoint-md) {
        display: inline;
        border-top: 0;
        border-right: 1px solid $border-color;

        &:last-child {
            border: 0;
        }
    }
}

.main-nav__link-home {
    @media screen and (min-width: $breakpoint-md) {
        display: none;
    }
}

.fa-home {
    @media screen and (max-width: $breakpoint-md-down) {
        display: none;
    }
}

.main-nav__link {
    display: block;
    padding: 0.5em 0;
    color: $secondary;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $bg-secondary;
        color: $primary;
    }

    @media screen and (min-width: $breakpoint-md) {
        display: inline;
        padding: 0.2em 1em;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $primary;
        }
    }
}

.main-nav__link.is-active {
    color: $primary;

    .fa-home {
        color: $primary;
    }
}

.main-nav__toggle {
    @media print {
        display: none;
    }

    @media screen and (min-width: $breakpoint-md) {
        display: none;
    }
}

.main-header__user {
    @media screen and (min-width: $breakpoint-md) {
        position: absolute;
        top: 0;
        right: $padding;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    // min-width and max-width can't have the same breakpoint
    @media screen and (max-width: $breakpoint-md-down) {
        // overwrite dropdown style on mobile

        .dropdown {
            margin-bottom: 0;
            width: 100%;
        }

        .dropdown-toggle {
            display: none;
        }

        .dropdown-menu {
            position: static;
            display: block;
            margin-bottom: 0.5em;
            padding: 0;
            box-shadow: none;

            border-right: 0;
            border-left: 0;
            border-color: $border-color;
        }

        .dropdown-item {
            padding-left: 0;
            padding-right: 0;
            color: $secondary;
            border-color: $border-color;
            border-bottom: 1px solid $border-color;
        }

        li:last-child button {
            border-bottom: 0;
        }
    }
}
