// FIXME: this only works with buttons if it is included *afterwards*

.input-group__input {
    flex: 1 1 auto;
    background-clip: unset;

    @extend .form-control;
}

.input-group__before,
.input-group__after {
    flex: 0 0 0%;
}

.input-group__input,
.input-group__before,
.input-group__after {
    border-radius: 0;
}

.input-group__before {
    margin-right: -1px;

    &:first-child {
        border-top-left-radius: 0.3em;
        border-bottom-left-radius: 0.3em;
    }
}

.input-group__after {
    margin-left: -1px;
    line-height: 1.6;

    &:last-child {
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
    }
}

// input-group btn full width on small screen
@media (max-width: $breakpoint-xs) {
    .input-group__xs-bottom {
        width: 100%;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0.3em !important;
    }
}

.input-group__input:focus,
.input-group__before:focus,
.input-group__after:focus {
    // highlighted border should be on top
    z-index: 1;
}

.addon {
    @extend %control-base;
    background-color: $body-bg;
    color: $text-color;
    min-width: 2.4em;
    text-align: center;
}

.input-group__after-outside {
    margin-bottom: 0.2em !important;
}
