.leaflet-popup-content {
    display: flex;
    flex-direction: row-reverse;

    min-height: 8em;
    margin: 0;
}

.leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 0;
}

.maps-popups-popup-text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: $padding;
    width: 14.5rem;
}

.maps-popups-popup-name {
    font-size: $font-size-lg;

    line-height: normal;
    page-break-inside: avoid;
    overflow-wrap: break-word;

    a {
        color: $link-color;
    }
}

.maps-popups-popup-meta {
    padding-bottom: 0.5 * $spacer;
}

.map-popup-upvotes i {
    color: $success;
}

.map-popup-downvotes i {
    color: $danger;
}

.map-popup-vote-count i {
    color: $primary;
}

.map-popup-upvotes,
.map-popup-downvotes,
.map-popup-vote-count,
.map-popup-comments-count {
    margin-right: $spacer;
}

.maps-popups-popup-image {
    width: 8em;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.maps-popups-popup-no-image {
    display: none;
}

.map-list {
    position: relative;
    z-index: 0;
}

.map-list__controls {
    position: absolute;
    inset: 0;
    padding: $padding 0;
}

/* On large screens, map and list are next to each other. The list should
 * maintain a width of 30em as long as possible.
 *
 * On small screens, map and list are one below the other. The map should
 * maintain a hight of 36vh as long as possible.
 *
 * In both cases, the complete component should fit on the screen (max-height
 * of 80vh).
 *
 * The size of the map should not change depending on the number of items in
 * the list.
 */

.map-container {
    margin-left: $spacer;
}

.map-list-combined {
    display: flex;
    flex-direction: column;
    position: relative;

    // max-height: 80vh;

    @media (min-width: $breakpoint) {
        flex-direction: row;
    }

    @media print {
        max-height: none;
    }
}

.map-list-combined__alert {
    position: absolute;
    width: 100%;
    z-index: 1;
}

// chrome bug workaround
.leaflet-control-zoom,
.leaflet-control-attribution,
.map-list-combined__map__search,
.map-infobox {
    will-change: transform;
}

.map-list-combined__map {
    flex: 0 1 44vh;
    z-index: 0;
    min-height: 100vh;
    position: relative;

    .map-list-combined__map__search {
        font-size: $font-size-base;
        width: 90%;
        position: absolute;
        left: $em-spacer;
        top: $em-spacer;
        z-index: 1000;

        .form-group {
            margin-bottom: 0;
        }

        @media (min-width: $breakpoint-xs) {
            width: 50%;
        }
    }

    .map-list-combined__map__search__error {
        padding: $em-spacer;
        border: none;
    }

    .map-infobox {
        font-size: $font-size-base;
        width: 90%;
        position: absolute;
        left: 1 * $em-spacer;
        top: 4.5 * $em-spacer;
        z-index: 1000;
        background-color: $body-bg;
        padding: $padding $padding $padding 3 * $padding;

        @media (min-width: $breakpoint-xs) {
            width: 50%;
        }

        i.fa-info-circle {
            color: $secondary;
            position: absolute;
            left: $padding;
            top: 1.2rem;
        }

        .infobox__close {
            position: absolute;
            padding: 0.25 * $padding 0.5 * $padding;
            right: 0;
            top: 0;
        }

        i.fa-times {
            color: $gray-lighter;
        }
    }

    ul {
        @extend .dropdown-menu;
        border: none;
        display: block;
    }

    @media (min-width: $breakpoint-xs) {
        flex-grow: 2;
        min-height: 100vh;
        margin-top: $spacer;
    }

    @media (min-width: $breakpoint-md) {
        margin-top: 0.25 * $spacer;
    }
}

.map-list-combined__list {
    flex: 0 1 44vh;

    @media (min-width: $breakpoint-xs) {
        flex-grow: 2;
        overflow-y: visible;
    }

    @media print {
        overflow: visible;
    }
}

.map-list-combined__icon {
    background-color: $secondary;
    color: $text-color-inverted;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;

    // box-shadow: 0 0 0 1px rgba(contrast-color($secondary), 0.8);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
    background-color: rgba($secondary, 0.6);

    div {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-top: 5px;
        text-align: center;
        border-radius: 15px;
        font-size: 12px $font-family-sans-serif;
        background-color: $secondary;
        color: $text-color-inverted;
        line-height: 30px;
    }
}
