.accordion {
    border: solid 1px $border-color;
    border-radius: 5px;

    // make border-radius work with contents
    overflow: hidden;
    margin-bottom: $padding * 1.8;
}

.accordion__title {
    display: block;
    position: relative;
    padding: 0.5 * $padding 1.7 * $padding 0.5 * $padding $padding;
    text-decoration: none;
    color: inherit;
    border-bottom: solid 1px $border-color;

    &.collapsed {
        border-bottom: none;
    }

    h2 {
        font-size: $font-size-lg;
        margin-top: $em-spacer * 0.2;
        margin-bottom: $em-spacer * 0.2;
    }

    i {
        position: absolute;
        right: $padding;
        transition: transform 0.3s;
        color: $primary;
        line-height: inherit;
    }

    &[aria-expanded="true"] i {
        transform: rotate(180deg);
    }
}

.accordion__body {
    padding: $padding;

    li {
        margin-bottom: 0.2em;
    }

    a[href^="/documents"]:before {
        font-family: "Font Awesome 6 Free", sans-serif;
        font-weight: 900;
        content: "\f15b"; // file
        margin-right: 0.3em;
    }
}
