.a4-follow__notification {
    .alert {
        margin-top: $spacer;
        font-size: inherit;
    }
}

.a4-btn--follow:before,
.a4-btn--following:before {
    font-family: "Font Awesome 6 Free", sans-serif;
    font-weight: 900;
    margin-right: 0.3em;
}

.a4-btn--follow:before {
    content: "\f067"; // plus
}

.a4-btn--following:before {
    content: "\f00c"; // check
}

.a4-btn--follow {
    @extend .btn;
    @extend .btn--secondary;
}

.a4-btn--following {
    @extend .btn;
    @extend .btn--light;
}
