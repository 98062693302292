.participation-tile__list {
    max-width: calc(100% + 2rem); // this is all to fit within the frame to be inline with above items
    margin: 0.5 * $spacer !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media screen and (min-width: $breakpoint-md) {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }
}

.maplist-item {
    display: flex;
}

.maplist-item__img {
    position: relative;
    flex-basis: 250px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.maplist-item__img-copyright {
    position: absolute;
    bottom: 0;
}

.maplist-item__label {
    margin-right: 0.5 * $spacer;
    margin-bottom: 0.5 * $spacer;
}

.maplist-item__roofline {
    font-size: $font-size-sm;
    color: $gray;
}

.maplist-item__title {
    margin-top: 0.5 * $spacer;
}

.maplist-item__description {
    color: $secondary;
}

.maplist-item__stats {
    position: absolute;
    bottom: $padding;
}

.maplist-item__corner-badge {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
}

.maplist-item__corner-badge--external {
    background-image: url("/static/images/badge_external_link.png");
}

.maplist-item__corner-badge--private {
    background-image: url("/static/images/badge_priva_projekt.png");
}

.maplist-item__corner-badge--semi-public {
    background-image: url("/static/images/badge_semi_public_projekt.png");
}

// popup specific styling
.maplist-popup-item__roofline {
    font-size: $font-size-sm;
}

.maplist-item-popup__proj-count,
.maplist-item-popup__status {
    color: $text-color;
    font-size: $font-size-xs;

    i {
        padding-right: 0.5 * $padding;
        color: $secondary;
    }
}

.maplist-item-popup__status.status-bar__past {
    padding: 0.25 * $padding;
}

.maplist-item-popup__proj-plan {
    background-color: $bg-secondary;
    padding: 0.5 * $padding $padding;
    margin: 0.5 * $padding (-$padding) auto;
}

// vertical tile structure
.participation-tile__vertical {
    .maplist-item { // for image
        flex-direction: column;
    }

    // to make whole tile clickable even when empty
    .maplist-item__link {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .maplist-item__label-img {
        margin-top: 0.5 * $spacer;
        margin-left: $spacer;
    }
}

// horizontal tile structure
.participation-tile__horizontal {
    .maplist-item { // image
        flex-direction: row;
    }
}
