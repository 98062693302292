.doc-toc {
    background-color: $body-bg;
    margin-bottom: 2 * $em-spacer;
    padding: 0;
    font-size: $font-size-lg;
    border: 1px solid $border-color;
    border-radius: 0.3em;
}

.doc-toc__toggle {
    display: block;
    position: relative;
    width: 100%;
    padding-right: 2em;

    i {
        transition: transform 0.3s;
        position: absolute;
        top: 0.7em;
        right: $padding;
    }

    &.collapsed i {
        transform: rotate(-180deg);
    }
}

.doc-toc__title {
    margin: 0;
    padding: ($padding * 0.5) $padding;
}

.doc-toc__content {
    padding: $padding;
    border-top: 1px solid $border-color;

    ol {
        margin-bottom: 0;
        padding-left: 2em;
    }

    li {
        margin-bottom: 0.1em;
    }
}

.doc-toc__link {
    color: inherit;
    text-decoration: none;

    &.active,
    &:hover,
    &:focus {
        color: $link-color;
    }
}
