details summary {
    cursor: pointer;
}

// Hides the default arrow on safari
details summary::-webkit-details-marker {
    display: none;
}

details summary > * {
    display: inline;
}

details summary > i {
    transition: transform 0.3s;
}

details[open] summary > i {
    transform: rotate(180deg);
}
