.module-content {
    padding-top: 2em;
    padding-bottom: 2em;

    background-color: $bg-tertiary;
}

.module-content--light {
    padding-bottom: 2em;
}
