.tabpanel {
    display: none;
    overflow-wrap: break-word;
    overflow-x: hidden;

    &.active {
        display: block;
    }
}

.tablist {
    border-bottom: 1px solid $border-color;
    text-align: center;
    margin-bottom: $em-spacer * 2;
    padding-top: 2em;
}

.tab {
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    padding: 0.4em 0.6em;
    margin-right: 0.5em;
    margin-bottom: -1.5px;
    text-decoration: none;
    color: inherit;

    border: 1px solid transparent;
    border-top-right-radius: 0.3em;
    border-top-left-radius: 0.3em;
    border-bottom: 0;

    &:hover,
    &:focus {
        color: $link-color;
    }

    &.active {
        border-color: $border-color;
        background-color: $body-bg;
    }
}

.tablist--right {
    text-align: right;
}

.tablist--left {
    text-align: left;
    min-width: 100%;

    @media screen and (min-width: $breakpoint) {
        min-width: auto;
    }
}

// FIXME this should be refactored - ensure tabs remain on single line mobile
@media (max-width: $breakpoint-md-down) {
    .tablist--left > .container {
        padding: 0 0.5 * $spacer;
    }
}

// This element is meant to be in combination with tablist--{opposite}
// It should contain elements with class .btn.btn--small

.tablist--left,
.tablist--right {
    font-size: $font-size-sm;

    &.dropdown,
    .btn {
        margin-bottom: -1px;
    }
}
