// generic component for having right-aligned and left-aligned content on a
// single line.

.lr-bar {
    @include clearfix;
}

.lr-bar__left {
    display: inline-block;
    margin: 0;
    margin-right: $padding;
}

.lr-bar__right {
    float: right;
    margin: 0 0 0 5px;

    > * {
        display: inline-block;
    }
}

.lr-bar--with-margin {
    margin-top: 1.5 * $em-spacer;
    margin-bottom: 0.5 * $em-spacer;

    &:first-child {
        margin-top: 0;
    }
}

.lr-bar__flex--wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: $breakpoint) {
        flex-wrap: unset;
    }

    &.nowrap {
        flex-wrap: unset;
    }
}

.lr-bar__actions {
    flex-direction: column;

    & > a {
        margin: 0.5 * $spacer 0;
    }

    @media screen and (min-width: $breakpoint) {
        display: inline-block;
        float: right;
        min-width: auto;
    }
}
