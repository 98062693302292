.switch-container {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: $spacer;

    @media screen and (min-width: $breakpoint-xs) {
        margin-bottom: 0;
    }
}

.switch__label > .switch__input[type="checkbox"] {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: -1;
}

.switch__label {
    cursor: pointer;
    position: relative;

    &:focus-within {
        outline: 3px solid $blue-light;
    }
}

.switch__label-text {
    vertical-align: super;
}

.switch__toggle:before,
.switch__toggle:after {
    content: "";
    margin-left: 0.5 * $spacer;
    display: inline-block;
    border-radius: 16px;
}

.switch__toggle:before {
    display: inline-block;
    height: 32px;
    width: 50px;
    background: $secondary;
    transition: all 0.4s ease-in-out;
}

.switch__toggle:after {
    position: absolute;
    top: 4px;
    right: 4px;
    transition: all 0.3s ease-in-out;
    height: 24px;
    width: 24px;
    background: $body-bg;
}

.switch__input[type="checkbox"]:checked {
    & + .switch__toggle:before {
        background: inherit;
        border: 1px solid $gray-lighter;
    }

    & + .switch__toggle:after {
        background: inherit;
        top: 4px;
        right: 22px;
        border: 1px solid $gray-lighter;
    }
}

.switch__group {
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.switch--btn {
    color: $secondary;
    background-color: $body-bg;
    border-color: $border-color;
    border-radius: 0;
    font-weight: normal;
    position: relative;
    padding-left: 0.5 * $padding;
    padding-right: 0.5 * $padding;

    &:active,
    &:hover,
    &:focus,
    &.active {
        color: $body-bg;
        background-color: $secondary;
        border-color: $secondary;
    }

    &:focus-within {
        @include fake-focus-shadow;
    }
}

.btn-group > div:first-child > .switch--btn {
    border-top-left-radius: ($spacer * 0.3);
    border-bottom-left-radius: ($spacer * 0.3);
}

.btn-group > div:last-child > .switch--btn {
    border-top-right-radius: ($spacer * 0.3);
    border-bottom-right-radius: ($spacer * 0.3);
}

.switch-btn-group-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-xs-down) {
        width: 100%;
    }
}

.switch-filter__label {
    width: 100%;
    padding-bottom: 0.5 * $padding;
}

.switch-filter__btn-group {
    min-width: 1px; // stop mapswitch swapping sides
}
