.list-item {
    padding: 0.7em $padding;
    display: block;
    position: relative;
    border: 1px solid $border-color;
    background-color: $body-bg;
    margin-bottom: $em-spacer;
    page-break-inside: avoid;
    scroll-margin-top: 2 * $spacer;
}

.list-item--squashed {
    border: 0;
    margin-bottom: 8px;

    @media print {
        border: 1px solid $border-color;
    }
}

.list-item--has-image {
    padding: 0;
}

.list-item--has-image__wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-xs) {
        flex-direction: row;
    }
}

.list-item--has-image__bg-colour,
.list-item--has-image__image {
    min-height: 7.5em;
    flex: 0 0 7.5em;
    background-color: $secondary;
}


.list-item--has-image__image {
    position: relative;

    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.list-item--has-image__copyright {
    position: absolute;
    bottom: 0;
}

.list-item--has-image__body {
    padding: 0.7em $padding;
    flex: 1 1 auto;
}

.list-item__title {
    display: inline-block;
    margin: 0 0 0.8rem;
    font-size: $font-size-lg;
}

.list-item__subtitle {
    margin-bottom: 0.3em;
    font-size: $font-size-sm;
}

.list-item__link {
    font-size: $font-size-sm;
}

.list-item__labels {
    @include clearfix;
    margin: 0 0 0.4rem;

    > div.label,
    > span.label {
        margin-left: 0.2 * $spacer;
        overflow: hidden;
        max-width: 25ch; // cuts label text after approx 30char
        text-overflow: ellipsis;
        white-space: nowrap;

        &.label--consideration,
        &.label--qualified,
        &.label--rejected,
        &.label--accepted {
            max-width: none;
        }
    }
}

.list-item__author {
    margin-right: 0.3em;
    font-weight: bold;
    display: block;

    @media screen and (min-width: $breakpoint-xs) {
        display: inline-block;
    }
}

.list-item__stats {
    display: flex;
    justify-content: flex-end;
    margin-left: 0.5 * $spacer;
}

.list-item__action {
    display: inline;
}

.list-item .is-read-only {
    padding: 0;
}

.list-item__icon {
    display: flex;
    margin-left: 0.75 * $spacer;
}

.list-item-empty {
    padding: 0.7em $padding;
    text-align: center;
}

.list-item__status--active {
    color: $primary;
}

.list-item__header {
    display: flex;
    flex-direction: column-reverse;
}

@media screen and (min-width: $breakpoint-xs) {
    .list-item__vote {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .list-item__header {
        flex-direction: row;
        justify-content: space-between;
    }
}
