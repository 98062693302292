html {
    background-color: $bg-secondary;
}

body {
    background-color: $body-bg;
}

.main-footer {
    @include clearfix;
    padding-top: $em-spacer * 1.5;
    padding-bottom: $em-spacer * 4;
    margin-top: $em-spacer * 7;

    font-size: $font-size-sm;
    text-align: right;
    background-color: $bg-secondary;
    color: $text-color;
}

.footer-nav__link {
    display: inline-block;
    padding-left: $padding;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
        color: inherit;
    }
}

.shariff {
    text-align: left;

    // stops buttons appearing empty on small screens
    .orientation-horizontal.button-style-standard li {
        max-width: 35px;
        min-width: 35px;
    }

    // found this value by experiment
    @media (width >= 386px) {
        min-width: 386px;
    }

    @media (width >= $breakpoint) {
        float: left;
    }
}
