.tile__lg {
    height: 75%;
}

.tile__lg--xs-screen {
    min-height: 300px;
}

.tile__md {
    height: 75%;

    @media screen and (min-width: $breakpoint-xs) {
        height: 50%;
    }
}

.tile__sm {
    height: 25%;
}

.storefront {
    display: flex;
    font-size: $font-size-base;
    padding-bottom: 3 * $padding;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: $breakpoint-xs) {
        padding-bottom: 6 * $padding;
    }
}

.storefront__col {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 224px;

    @media screen and (min-width: $breakpoint-md) {
        width: 33.3%;
    }

    @media screen and (min-width: $breakpoint-xs) {
        width: 50%;
        min-height: 632px;
    }
}

.storefront__first-col {
    margin-right: $spacer;
}

.storefront__mid-col {
    margin-left: $spacer;
    margin-right: $spacer;

    @media screen and (min-width: $breakpoint-xs) {
        margin-right: 0;
    }

    @media screen and (min-width: $breakpoint-md) {
        margin-right: $spacer;
    }
}

.storefront__last-col {
    margin-left: $spacer;

    @media screen and (max-width: $breakpoint-xs-down) {
        margin: auto;
    }
}

.storefront__item {
    margin: $spacer 0;
    padding: 1.5 * $padding;
    flex: 0 1 6rem;
    flex-wrap: wrap;
    position: relative;
    background-color: $body-bg;
    overflow-wrap: break-word;

    .list-item__stats {
        font-size: $font-size-sm;
        color: $gray;
    }

    .item-detail__labels {
        float: left;
    }

    @media screen and (min-width: $breakpoint-xs) {
        flex: none;
    }
}

.storefront__sm-text {
    margin-bottom: 0;
    padding-top: 18%;
    color: $gray;
}

.storefront__lg-text {
    margin: 0;
    color: $white;
    width: 75%;
}

.storefront__xl-text {
    font-size: 1.3 * $font-size-xxl;

    @media screen and (min-width: $breakpoint) {
        font-size: 1.5 * $font-size-xxl;
    }
}

.storefront__xxl-text {
    margin: 0;
    font-size: 2 * $font-size-xxl;
    line-height: 1.5 * $font-size-xxl;
}

.storefront__quote-text {
    font-size: $font-size-lg;
    font-weight: bold;
    font-style: italic;
    padding: 0.7 * $padding 0;
    color: $text-color;

    @media screen and (min-width: $breakpoint) {
        padding: 5% 0; // to stop overflow of content on small screen
    }
}

@media screen and (max-width: $breakpoint-xs-down) {
    .storefront__quote-text,
    .storefront__district-text {
        margin-top: 0;
    }

    .storefront__proj {
        min-height: 250px;
    }
}

.storefront__stat-sm {
    font-size: $font-size-lg;
    background-color: $primary;
    color: $white;
}

.storefront__stat-lg {
    font-size: $font-size-lg;
    background-color: $secondary;
    color: $white;
}

.storefront__stat-text {
    width: 75%;
    margin-top: 0.5 * $spacer;
}

.storefront__quote,
.storefront__proj,
.storefront__district,
.storefront__proj-quote {
    text-align: center;
    box-shadow: 0 0 20px 0 $shadow;
}

.storefront__opinion-text,
.storefront__proj-text,
.storefront__quote-text {
    margin: auto;
    min-width: 200px;

    @media screen and (min-width: $breakpoint) {
        width: 75%;
    }
}

.storefront__proj-text-wide {
    width: 100% !important;
}

.storefront__district-text {
    padding-bottom: $padding;

    @media screen and (max-width: $breakpoint-down) {
        font-size: $font-size-lg;
    }
}

.storefront__district {
    padding: 2 * $padding;
    color: $secondary;

    @media screen and (min-width: $breakpoint) {
        padding: 3 * $padding;
    }
}

.storefront__ending-icon {
    position: absolute;
    width: 20%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.storefront__ending-icon-spacer {
    padding-bottom: $padding;
}

.storefront__stat-arrow {
    font-size: 2 * $font-size-xl;
    text-align: center;
    margin: auto;
    max-width: 1.5 * $font-size-xl;
}

.storefront__stat-spacer {
    height: $spacer;

    @media screen and (min-width: $breakpoint-md) {
        height: 3 * $spacer;
    }
}
