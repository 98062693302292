// See https://csswizardry.com/2013/04/shame-css/ about this file

/* stylelint-disable property-no-vendor-prefix */

// CKEditor applies `box-sizing: content-box` everywhere. The outer element has
// a 1px border, so if set to `width: 100%` it will take up an additional 2px.
.cke_chrome {
    box-sizing: border-box !important;
}

// CKEditor uses background images for its toolbar
.cke_button_icon {
    // stylelint-disable-next-line scale-unlimited/declaration-strict-value
    -webkit-print-color-adjust: exact;
}

// safari doesn't allow styling of these otherwise
// see https://css-tricks.com/webkit-html5-search-inputs/
input[type="search"] {
    -webkit-appearance: none;
}

// used by adhocracy4
.submit-button {
    @extend .btn;
    @extend .btn--primary;
}

.cancel-button {
    @extend .btn;
    @extend .btn--light;
}

// used by adhocracy4 for the follow button, long-term we'd like
// our own classes.
.btngroup .btn {
    @extend .btn--light;
    @extend .btn--small;

    &:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    &:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

// maps usually have a height of 300px in their element style, defined in adhocracy4.
// However, 300px is not enough for the list view so we add a min-height here instead.
.map-list [data-map] {
    min-height: 500px;
}

// add underline to links within paragraphs added by wagtail or ckeditor
.block,
.rich-text {
    p {
        a {
            text-decoration: underline;
        }

        img {
            height: auto !important;
        }
    }
}

// stops squeezing of images added via ck editor when width and height left in
.item-detail__basic-content,
.item-detail__content,
.paragraph__content,
.col-lg-8,
.col-lg-6 {
    p > img {
        height: auto !important;
        max-width: 100% !important;
    }
}

// stops modal moveing site content when opened
.modal-open {
    padding-right: 0 !important;
}

// FIXME needed for safari as break-word does not work in tile elements, reasons unknown
// issue ref #5193
.l-tiles-2 address {
    overflow-wrap: anywhere;
}
