.richtext-image {
    display: block;
    margin-bottom: $em-spacer;
    margin-left: auto;
    margin-right: auto;

    &.left {
        margin-left: 0;
    }

    &.right {
        margin-right: 0;
    }
}
