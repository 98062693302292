.participation-tile__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    margin-left: -$spacer;
    margin-right: -$spacer;

    @media screen and (min-width: $breakpoint-xs) {
        justify-content: flex-start;
    }

    @media screen and (min-width: $breakpoint-md) {
        margin: 0;
    }
}

.participation-tile__list-container {
    flex: 0 1 auto;

    @media (min-width: $breakpoint-xs) {
        flex: 2 0 44vh;
        overflow-y: visible;
    }
}

.participation-tile__list-header {
    width: 100%;
    text-align: center;
}

.participation-tile__list {
    max-width: calc(100% + 2rem); // this is all to fit within the frame to be inline with above items
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media screen and (min-width: $breakpoint-md) {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }
}

.participation-tile__content {
    padding: 1.5 * $padding;
}

.participation-tile__horizontal,
.participation-tile__vertical {
    border: 1px solid $border-color;
    position: relative;
    overflow-wrap: break-word;
    background-color: $body-bg;
}

.participation-tile__horizontal {
    width: 100%;
    margin: 0 $spacer $spacer;

    @media screen and (min-width: $breakpoint-md) {
        margin: 0 $spacer $spacer 5 * $spacer;
    }

    .participation-tile__body { // statusbar position
        height: 250px;
    }

    .participation-tile__content { // for status bar flex for image
        height: inherit;
        position: relative;
        flex: 1;
    }
}

.participation-tile__vertical {
    width: 100%;
    margin: 0.5 * $spacer;
    flex-grow: 1 0 100%;
    min-height: 350px;

    @media screen and (min-width: $breakpoint-xs) {
        flex-basis: auto;
        flex-grow: 1;
        max-width: calc(100% * (1 / 2) - 1rem - 1px);
    }

    @media screen and (min-width: $breakpoint) {
        max-width: calc(100% * (1 / 3) - 1rem - 1px);
    }

    @media screen and (min-width: $breakpoint-lg) {
        max-width: calc(100% * (1 / 4) - 1rem - 1px);
    }
}

.participation-tile__title {
    margin-top: 0;
}

.participation-tile__btn {
    position: absolute;
    left: 3 * $spacer;
    right: 3 * $spacer;
    bottom: 4 * $spacer;
}

.participation-tile__spacer {
    height: 6.5 * $spacer;
}

.participation-tile__item-count,
.participation-tile__proj-count,
.participation-tile__status {
    color: $text-color;
    font-size: $font-size-xs;

    i {
        padding-right: $padding;
        color: $secondary;
    }
}

.participation-tile__status-active {
    color: $primary;
}

.participation-tile__status-inactive {
    color: $gray;
}
