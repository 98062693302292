.editpoll__question-container,
.editpoll__actions-container,
.commenting {
    margin-bottom: $spacer;

    @extend .row;
}

.editpoll__question,
.commenting__content {
    margin-bottom: $spacer;

    @extend .col-lg-9;
}

.editpoll__question--border,
.commenting__content--border {
    margin-bottom: 0;
    margin-top: 0;
    padding: $padding;
    border: 1px solid $border-color;
    border-radius: ($padding * 0.5);
}

.commenting--toc__button {
    display: block;
    width: 100%;
    text-align: left;

    &.active {
        color: $primary;
        background-color: $gray-lightest;
    }
}

.editpoll__question-actions,
.editpoll__menu-container,
.commenting__actions {
    margin-bottom: $spacer;
    text-align: right;

    @extend .col-lg-3;
}

@media (min-width: $breakpoint) {
    .commenting {
        @include clearfix;
    }

    .commenting__actions {
        text-align: left;
    }
}
