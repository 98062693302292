.a4-comments__comment {
    border-top: none;
}

.a4-comments__text--highlighted {
    border-left: solid 2px $primary;
    padding-left: 0.5 * $spacer;
}

.a4-comments__box {
    margin-top: $spacer * 2;
}

.a4-comments__anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
    height: 1px;
}

.a4-comments__commentbox__form {
    margin-top: $spacer;

    :first-child {
        display: flex;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    .row {
        @extend .l-flex-row;

        flex-direction: row-reverse;
    }
}

.a4-comments__comment-form__form {
    .form-group {
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;
    }
}

.a4-comments__filters__parent--closed,
.a4-comments__filters,
.a4-comments__action-bar-container,
.a4-comments__box--user > .row {
    @extend .l-flex-row;
}

// edit form for parent comments
.a4-comments__box .general-form .row {
    @extend .l-flex-row;

    flex-direction: row-reverse;

    .cancel-button {
        margin-right: 0.5 * $spacer;
    }
}

.a4-comments__child--list {
    padding-left: 2 * $spacer;
    border-left: 1px solid $primary;

    // edit form for child comments
    .general-form {
        padding-top: $spacer;
        margin-bottom: $spacer * 2;

        .row {
            @extend .l-flex-row;

            flex-direction: row-reverse;
        }
    }
}

.a4-comments__textarea--big {
    min-width: 100%;
}

.a4-comments__filters__parent--closed,
.a4-comments__filters,
.a4-comments__action-bar-container {
    align-items: flex-start;
    flex-wrap: wrap;
}

.a4-comments__filters__parent--closed,
.a4-comments__filters,
.a4-comments__action-bar-container > .rating {
    margin-top: $spacer * 0.5;
    margin-bottom: $spacer * 1.5;
}

.a4-comments__action-bar {
    margin-top: $spacer * 0.5;
    margin-bottom: $spacer * 1.5;
}

.a4-comments__moderator {
    @extend .label;
}

.a4-comments__comment-control-bar {
    margin-top: $spacer * 2;

    .form-group--inline {
        display: flex;
        justify-content: space-between;
    }

    .form-group {
        margin-bottom: 0;
    }
}

.a4-comments__filters__search {
    @extend .form-group;
    @extend .u-inline-flex;

    margin-bottom: 0.5 * $spacer;
    position: relative;

    @media screen and (width <= 478px) {
        flex-grow: 1;
    }
}

.a4-comments__filters__search-result {
    position: absolute;
    right: 45px;
    top: 7px;

    span {
        color: $text-color-gray;
    }
}

.a4-comments__filters__search-input,
.a4-forms__select__input {
    @extend .input-group__input;

    border-radius: 0.3em !important;
}

.a4-comments__filters__search-btn {
    @extend .input-group__after;
}

.a4-comments__filters__btn {
    @extend .btn--light;
}

.a4-comments__dropdown {
    text-align: right !important;
    margin-right: -0.7 * $spacer;

    .btn--link {
        @extend .btn--small;
        @extend .btn--light;

        color: $text-color;

        &:hover {
            color: $text-color !important;
        }
    }

    .dropdown-item {
        padding: 0!important;
    }
}

// No user images in mB
.a4-comments__user-img {
    display: none;
}

.a4-comments__author,
.a4-comments__deleted-author {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 0.5 * $spacer;
}

.a4-comments__action-bar__btn,
.a4-comments__filters__show-btn {
    @extend .btn--light;
    @extend .btn--small;
    @extend .btn--icon;
}

.a4-comments__submit-input {
    @extend .btn--primary;
}

.btn.a4-comments__action-bar__btn {
    margin-right: 0.5 * $spacer;

    &:last-child {
        margin-right: 0;
    }
}

.a4-comments__text,
.a4-comments__list {
    margin-top: $spacer * 0.7;
}

.a4-comments__char-count {
    display: flex;
    justify-content: flex-end;
    font-size: $font-size-sm;
    margin-top: 0.4em;
    margin-left: auto;
    margin-bottom: 0;
}

.a4-comments__char-count-word {
    margin-left: 0.2em;
}

.a4-comments__submission-date {
    font-size: $font-size-sm;
    display: block;
}

.a4-comments__read-btn-container {
    display: flex;
    justify-content: flex-start;
}

.a4-comments__read-btn {
    @extend .btn--link;
}

.a4-comments__dropdown-container {
    margin-left: auto;
    margin-right: 8px;

    @media screen and (width <= 478px) {
        margin-right: 1.5em;
    }
}

.a4-comments__filters__dropdown {
    @media screen and (width <= 478px) {
        flex-grow: 1;

        > .dropdown button {
            width: 100%;
            max-width: initial;
        }
    }
}

.modal-url-bar-button.active {
    background-color: $success;
    color: $text-color-inverted;
}

.a4-comments__comment-form__heading-comments-allowed,
.a4-comments__commentbox__subtitle {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
}

.a4-comments__comment-form__actions {
    display: inline-flex;
}

.a4-comments__comment-form__actions__left {
    > button {
        margin-right: 0.5em;
    }
}

