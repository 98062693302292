.live_questions__filters {
    @media screen and (min-width: $breakpoint-xs) {
        display: inline-flex;
    }
}

.live_questions__filters--parent {
    width: 100%;
    justify-content: space-between;
    margin-bottom: $spacer;

    @media screen and (min-width: $breakpoint-xs) {
        display: inline-flex;
    }
}

.live_questions__filters--dropdown {
    @media screen and (min-width: $breakpoint-xs) {
        margin-right: $spacer;
        margin-bottom: 0;
    }

    button {
        min-width: 100%;
    }
}

.live_questions__filters--btns {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;

    .switch--btn {
        border-radius: $border-radius;
    }
}

.live_questions__filters--screen-btn {
    width: 100%;
}

.live-question__action-bar {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.live_questions__question {
    font-size: $font-size-lg;
    margin-bottom: 0.25 * $spacer;
}

.infobox__box {
    background-color: $bg-tertiary;
    padding: 0.5 * $spacer $spacer;
    font-size: $font-size-sm;
    margin-bottom: $spacer;

    i {
        position: absolute;
        color: $primary;
        line-height: 1.2rem;
    }
}

.infobox__text {
    margin-left: 2 * $spacer;
}

.infobox__icon {
    margin-left: -0.6 * $spacer;
    position: absolute;
}

// infobox bigger
@media screen and (min-width: $breakpoint-xs) {
    .infobox {
        display: inline-flex;
    }

    .infobox__box {
        text-align: center;
        padding: $spacer;
        margin-right: $spacer;
    }

    .infobox__box > i {
        display: block;
        position: relative;
    }

    .infobox__text {
        margin-left: 0;
    }

    .infobox__icon {
        display: block;
        position: relative;
        margin: auto;
    }

    .infobox__box--last {
        margin-right: 0;
    }
}

.progress {
    margin-bottom: $spacer;
    position: relative;
}

.progress-bar {
    display: flex;
    height: 1.5rem;
    background-color: $primary-tint;
    border-radius: 0.25rem;
    position: absolute;
}

.progress-bar__stats {
    position: relative;
    z-index: 1;
}

.checkbox-btn {
    display: inline-block;
}

.live_questions_infographic__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
}

.live_questions_infographic__logo-container {
    width: 100%;  // required when used in a flex container
    max-width: $wrapper-width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-height: 2 * $spacer;

    @media screen and (min-width: $breakpoint) {
        justify-content: flex-start;
    }
}

.live_questions_infographic__logo {
    position: relative;
    top: -5rem;

    @media screen and (min-width: $breakpoint-lg) {
        left: -5rem;
    }
}

.live_questions_infographic {
    padding-bottom: 2 * $spacer;

    .block--infographic__icon-block {
        margin: auto;
    }

    .block--infographic__icon {
        padding-right: 0;
    }

    a {
        color: $body-bg;
        text-decoration: underline;
        display: block;

        @media screen and (min-width: $breakpoint) {
            display: inline-block;
        }
    }
}

.live_questions__like {
    padding-top: 0.5 * $spacer;
}

.live_question__presentation {
    margin-top: 5 * $spacer;
}

.fa-stack,
.fa-stack-2x {
    font-size: 1rem;
    line-height: 1rem;
    height: 1rem;
}

.fa-stack-1x {
    font-size: 0.6rem;
    line-height: inherit;
}

.live_questions__select > .select2-container {
    min-width: 100%;

    @media screen and (min-width: $breakpoint-xs) {
        min-width: 15rem;
    }
}

.live_questions__char-count {
    color: $gray-lighter;
    font-size: $font-size-sm;
    margin-top: -0.75 * $spacer;
    margin-bottom: $spacer;
}

.live_questions__anchor {
    position: relative;
    bottom: -7 * $spacer;
    margin-bottom: 7 * $spacer;
}
