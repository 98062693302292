button.select-item {
    min-width: 11em;
    padding-left: 2em;

    position: relative;

    white-space: normal;
}

.select-item-desc {
    display: block;
    font-size: $font-size-sm;
}

.select-item-indicator {
    position: absolute;
    top: 0.7em;
    left: 0.6em;
}
