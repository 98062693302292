%button-base {
    @extend %control-base; // needed for stretched dropdowns ect.

    text-decoration: none;
    font-weight: bold;
}

// FIXME after redesign completed, tidy code to only include dashboard buttons
// remove is-disabled styling and use attribute if required
.btn {
    @extend %button-base;

    &.is-disabled {
        opacity: 0.75;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.btn--light {
    @extend .btn-light;

    background-color: $body-bg;
    font-weight: normal;
    border-color: $border-color;
    color: $secondary;

    &:focus,
    &:hover,
    &:active,
    &:focus-within,
    &:focus-visible {
        @include fake-focus-shadow-light;

        background-color: $bg-secondary;
    }
}

.btn--primary {
    @extend .btn-primary;

    border-color: $primary;
    font-size: $font-size-base;

    &:focus-within {
        box-shadow: 0 0 0 0.25rem rgb(213, 38, 111, 0.5);
    }
}

.btn--secondary {
    @extend .btn-secondary;

    border: none;
}

.btn--danger {
    @extend .btn-danger;
}

.btn--link {
    padding-left: 0; // horizontal paddings would make the button look off on mobile
    padding-right: 0;
    border-color: transparent;
    background-color: transparent;

    color: $link-color;
    text-decoration: underline !important;
    font-weight: normal;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $link-hover-color !important;
        border-color: transparent;
        background-color: transparent;
    }

    &.is-disabled {
        color: $text-color !important;
        border-color: transparent;
        background-color: transparent;
        cursor: not-allowed;
    }

    &.collapsible {
        .link--less {
            display: none;
        }

        .link--more {
            display: block;
        }
    }

    .show + &.collapsible {
        .link--less {
            display: block;
        }

        .link--more {
            display: none;
        }
    }
}

.btn--select {
    @include text-truncate;
    max-width: 17rem;

    // leave space for caret
    padding-right: 2.2em;
    position: relative;

    i {
        position: absolute;
        top: 0.4em;
        right: 0.7em;
    }
}

.btn--small {
    padding: 0.3em 0.6em;
    font-weight: inherit;
}

.btn--huge {
    padding: 0.8em 2em;
}

.btn--full {
    display: block;
    width: 100%;
}

.btn--attached-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn--attached-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group__container {
    margin-bottom: $spacer;
}

.btn-group {
    .dropdown {
        display: inline-block;
    }

    > *,
    .dropdown-toggle {
        border-radius: 0;
        margin-left: -1px;

        &:focus {
            // highlighted border should be on top
            z-index: 1;
            position: relative;
        }
    }

    > :first-child {
        border-top-left-radius: 0.3em;
        border-bottom-left-radius: 0.3em;
        margin-left: 0;
    }

    > :last-child,
    > :last-child .dropdown-toggle {
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
    }
}

@media print {
    .btn {
        background-color: $body-bg;
        color: $print-black;
        border-color: $border-color;
    }
}

.btn--icon {
    i {
        padding-right: 0.5rem;
    }
}

.btn--icon-end {
    i {
        padding-left: 0.5rem;
    }
}

.btn--align-left {
    padding-left: 0;
}

.btn--close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0.5;
    margin-left: auto;
}
